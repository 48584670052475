.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    background-color: rgba(0, 0, 0, .5);
    z-index: 10;
}

.modalContent {
    padding: 25px;
    background-color: white;
    border-radius: 16px;
    min-width: 250px;
}

.modal.active {
    display: flex;
    justify-content: center;
    align-items: center;
}
