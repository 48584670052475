.chart {
    margin: 10px;
    background-color: rgba(0, 128, 128, .1);
    border: 1px solid teal;
}

.chartTitleLine {
    text-align: right;
    padding: 1px 5px 3px;
    border-bottom: 1px solid teal;
    cursor: move;
}

.chartTitleLine .title {
    display: inline-block;
    text-align: left;
    width: calc(100% - 25px);
}
